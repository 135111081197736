<template>
  <div>
    <div class="row mb-3">
      <div class="col-md-12">
        <h2>Search Terms</h2>
      </div>
      <div class="col-md-3">
        <label>Date From</label>
        <date-picker
          input-class="form-control"
          placeholder="Date From"
          v-model="filter.from"
        />
      </div>
      <div class="col-md-3">
        <label>Date To</label>
        <date-picker
          input-class="form-control"
          placeholder="Date To"
          v-model="filter.to"
        />
      </div>
      <div class="col-md-3">
        <label>Sort</label>
        <select class="form-control" v-model="filter.sort">
          <option value="latest">Latest</option>
          <option value="popular">Most Searched</option>
        </select>
      </div>
      <div class="col-md-3">
        <label>.</label>
        <button class="btn btn-primary w-100" @click.prevent="fetch">Filter</button>
      </div>
    </div>
    <div class="card">
      <div class="card-body" v-if="!is_loading">
        <h4>{{ pagination.records }} Records</h4>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Term</th>
              <th>Searches</th>
              <th>Last Searched</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(term, i) in terms" :key="`term-${i}`">
              <td>{{ term.term }}</td>
              <td>{{ term.searches }}</td>
              <td>{{ term.last_searched }}</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <pagination
              v-model="pagination.page"
              :records="pagination.records"
              @paginate="paginate"
              :perPage="10"
            />
          </div>
        </div>
      </div>
      <loading v-if="is_loading" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      terms: [],
      is_loading: true,
      filter: {
        sort: "latest",
        from: null,
        to: null,
      },
      pagination: { page: 1, records: 1 },
    };
  },

  methods: {
    fetch() {
      this.is_loading = true;
      let url = `/api/v1/admin/search-terms?page=${this.pagination.page}&sort=${this.filter.sort}`

      if(this.filter.from) {
        url += `&from=${moment(this.filter.from).format('YYYY-MM-DD')}`
      }

      if(this.filter.to) {
        url += `&to=${moment(this.filter.to).format('YYYY-MM-DD')}`
      }

      this.$axios
        .get(url)
        .then((response) => {
          this.terms = response.data.terms;
          this.pagination = response.data.pagination;
          this.is_loading = false;
          this.filter = response.data.filter
        });
    },
  },
};
</script>
